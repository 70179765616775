import { render, staticRenderFns } from "./FarleftSidebar.vue?vue&type=template&id=219ed922&scoped=true&lang=html&"
import script from "./FarleftSidebar.vue?vue&type=script&lang=js&"
export * from "./FarleftSidebar.vue?vue&type=script&lang=js&"
import style0 from "./FarleftSidebar.vue?vue&type=style&index=0&id=219ed922&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "219ed922",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
installComponents(component, {VIcon,VList,VListItem,VListItemGroup})
